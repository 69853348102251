import { Options, Vue } from "vue-class-component";
import { IDepartment } from "@/types/Department";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { notify } from "@/services/helpers";

@Options({
  name: "NewDepartment",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        name: yup
          .string()
          .required("Please provide a name.")
          .min(3, "Must be at least 3 characters in length.")
          .max(20, "Must not exceed 20 characters in length."),
      }),
    };
  },
})
export default class NewDepartment extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  newDepartment: IDepartment = {
    id: 0,
    name: "",
    isActive: true,
    employeesCount: 0,
  };
  crumbs: any = ["Dashboard", "Department", "New Department"];
  loading = false;

  $refs!: {
    confirmDialogue: HTMLFormElement;
  };

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  message = "";
  async save() {
    this.loading = true;
    this.message = "";
    this.workForceService
      .postOrPut<void>(
        "/departments",
        this.newDepartment as IDepartment,
        undefined
      )
      .then((response: any) => {
        this.loading = false;
        if (!response.isError) {
          notify("Department added successfully.", "Success", "success");
          this.goToRoute("/admin/departments");
        } else {
          this.message = response.errors;
          notify(response.errors, "Error", "danger");
        }
      });
  }
}
