<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->
  <div class="custom-container">
    <!--    Page Title-->
    <page-title>
      <template #title>
        <span class="h4 text-primary">New Department</span>
      </template>
    </page-title>

    <div class="row pt-2">
      <div class="col-12">
        <Form @submit="save" v-slot="meta" :validation-schema="schema">
          <!--            Row 1: First Name & Last Name-->
          <div class="row justify-content-center">
            <div class="col-xs-12 col-md-6 form-group">
              <label>Department Name</label>
              <input-field icon="id-card">
                <template v-slot:input>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Name"
                    id="name"
                    :class="meta.errors.name ? 'is-invalid' : ''"
                    v-model="newDepartment.name"
                  />
                  <ErrorMessage name="name" class="invalid-feedback" />
                </template>
              </input-field>
            </div>
          </div>

          <!--        Buttons-->
          <div class="row mb-5 mt-3 justify-content-center">
            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="button"
                class="btn-secondary btn-lg-cus"
                @click.prevent="this.$router.back()"
              >
                Cancel
              </button>
            </div>

            <div
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group"
            >
              <button
                type="submit"
                class="btn-primary btn-lg-cus"
                :disabled="loading"
              >
                <span>Save </span>
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div
                v-if="message"
                v-html="message"
                class="alert alert-danger small"
              ></div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <!--  Modal-->
  <ConfirmationModal ref="confirmDialogue"></ConfirmationModal>
</template>

<script src="./new-department.ts" />
<style scoped></style>
